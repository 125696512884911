.folderLink {
    box-shadow: var(--card_box_shadow);
    border-radius: var(--radius);
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.folderLink:hover {
    box-shadow: var(--card_hover_box_shadow);
}

.folderLink .navLinkButton {
    --hover_bg: var(--bg);
    width: 100%;
    padding: calc(var(--padding_btn) * 2);
}

.folderLink button.button {
    margin-right: var(--padding_btn)
}