/*Brand Guide Page start*/






/*Company Admin*/


.imgDropZone {
	width: 250px;
}




.brand-logo-inverse label {
	background-color: #000;
}

/*Portal*/
.portalLogo {}

.portalLogo img {
	max-height: 100px;
	object-fit: contain;
	width: 100%;
}


/*Copy Text*/
.copyButton {
	background-color: var(--bg1);
	padding: 0.1em 0.5em;
	position: relative;
	transition: all 0.2s ease-in-out;
}

.copyButton::before {
	position: absolute;
	left: 0.5em;
	top: 50%;
	transform: translateY(-50%);
	opacity: 0;
	transition: all 0.2s ease-in-out;
}

.copyButton:hover {
	padding-left: 1.5em;
}

.copyButton:hover::before {
	opacity: 1;
}

.customDomainForm.active label::after {
	content: " Active";
	font-weight: bold;
}