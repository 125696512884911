.planCardContainer {
    container: planCard / inline-size;
}

.planCard {
    display: grid;
    grid-template-columns: 150px 1fr;
}

.planCard .hero {
    border-right: solid 1px var(--bg2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: var(--gap_min);
    margin-right: var(--gap_min);
}

.planCard .info {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.planCard .info .infoItem {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    align-items: center;
    line-height: 1.2em;
}

.planCard .info .infoItem .storage {
    width: 100%;
}

.planCard .info .infoItem .storage .col-2 p:last-child {
    text-align: right;
}

.planCard .info .col-2,
.planCard .info .col-auto {
    gap: 0.5em;
}

@container planCard (max-width: 400px) {
    .planCard {
        grid-template-columns: 1fr;
    }

    .planCard .hero {
        border-right: none;
        border-bottom: solid 1px var(--bg2);
        padding-right: 0;
        padding-bottom: var(--gap_min);
        margin-right: 0;
        margin-bottom: var(--gap_min);
    }
}