.portal.page {
    max-width: 100vw;
    width: 100%;
    padding: 0;
}

.portal.page .pageBody {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-items: center;
    min-height: 100vh;
}

@media screen and (max-width: 800px) {
    .portal.page .pageBody {
        grid-template-columns: 1fr;
    }
}

.portal.page .portalBody {
    display: flex;
    gap: var(--gap);
    flex-direction: column;
    width: clamp(300px, 80%, 400px);
}

.portalHero {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--white);
    background-image: linear-gradient(45deg, var(--primary), var(--secondary));
}

.portalHero::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-image:
        linear-gradient(#fff 1px, transparent 1px),
        linear-gradient(90deg, #fff 1px, transparent 1px);
    background-size: 200px 200px;
    opacity: 0.2;
}

.portalHero::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-image:
        linear-gradient(#fff 1px, transparent 1px),
        linear-gradient(90deg, #fff 1px, transparent 1px);
    background-size: 200px 200px;
    opacity: 0.2;
}

.portalHero .portalHeroContent {
    --text_color: #fff;
    --h1_size: clamp(2rem, 5vw, 4rem);
    background-color: rgba(255, 255, 255, 0.15);
    width: clamp(300px, 80%, 600px);
    padding: var(--gap);
    color: var(--text_color);
    text-align: left;
    backdrop-filter: blur(25px);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
}