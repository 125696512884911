.popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    --width: auto;
}

.popup_container {
    background-color: var(--bg);
    padding: var(--gap);
    border-radius: var(--radius);
    width: var(--width);
    max-width: 95%;
    max-height: 95%;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--gap_min);
    border: none;
    outline: none;
}

.popup.small {
    --width: 400px;
}

.popup_overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
}


/* .popup {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    background-color: var(--bg);
    padding: var(--gap);
    gap: var(--gap_min);
    border: none;
    outline: none;
    border-radius: var(--radius);
    overflow-y: auto;
}

.popup::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
}

.popup.small {
    max-width: 400px;
} */