:root {
    /*Colors*/
    --primary: hsl(240, 10%, 20%);
    --secondary: hsl(240, 10%, 20%);
    --text_color: hsl(240, 10%, 20%);
    --text_hover: hsl(240, 10%, 20%);
    --border_color: hsl(240, 10%, 20%);
    --bg: hsl(240, 10%, 100%);
    --bg1: hsl(240, 10%, 96%);
    --bg2: hsl(240, 10%, 90%);
    --bg3: hsl(240, 10%, 85%);
    --bg4: hsl(240, 10%, 75%);
    --dc: hsl(240, 10%, 20%);
    --dc1: hsl(240, 10%, 25%);
    --dc2: hsl(240, 10%, 30%);
    --dc3: hsl(240, 10%, 35%);
    --success: hsl(149 100% 34%);
    --error: #DE350B;

    --box_shadow: 0 0 25px rgba(0, 0, 0, 0.05);
    --input_box_shadow: 0 0 0 1px var(--primary);

    --card_box_shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
    --card_hover_box_shadow: 0 5px 15px rgba(0, 0, 0, 0.12);

    /*Fonsts*/
    --heading_font: "Poppins", sans-serif;
    --sub_heading_font: "Poppins", sans-serif;
    --paragraph_font: "Inter", sans-serif;
    --body_size: 15px;
    --h1_size: 2.49rem;
    --h2_size: 2.07rem;
    --h3_size: 1.73rem;
    --h4_size: 1.44rem;
    --h5_size: 1.2rem;
    --caption_size: 0.83rem;
    --small_size: 0.69rem;
    --heading_line_height: 1.1em;
    --body_line_height: 1.65em;

    /**/
    --max_width: 800px;
    --padding: 10px 12px;
    --padding_btn: 0.4em;
    --gap: 25px;
    --gap_min: 10px;
    --radius: 4px;
}

.dark {
    --primary: hsl(240, 5%, 90%);
    --secondary: hsl(240, 5%, 90%);
    --text_color: hsl(240, 5%, 90%);
    --text_hover: hsl(240, 5%, 95%);
    --border_color: hsl(240, 5%, 90%);
    --bg: hsl(240, 10%, 15%);
    --bg1: hsl(240, 10%, 18%);
    --bg2: hsl(240, 10%, 21%);
    --bg3: hsl(240, 10%, 24%);
    --bg4: hsl(240, 10%, 34%);
    background-color: var(--bg);
}


@media only screen and (max-width: 600px) {
    :root {
        --body_size: 15px;
    }
}

* {
    margin: 0;
    padding: 0;
}

*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    font-size: var(--body_size);
    font-family: var(--paragraph_font);
    scroll-behavior: smooth;
}

body {
    font-size: var(--body_size);
    background-color: var(--bg);
    color: var(--text_color);
    position: relative;
}

p {
    line-height: var(--body_line_height);
}

p, span {
    color: var(--text_color);
    font-family: var(--paragraph_font);
}

.h1Text, .h2Text, .h3Text, .h4Text, .h5Text,
h1, h2, h3, h4, h5 {
    color: var(--text_color);
    font-family: var(--heading_font);
    line-height: var(--heading_line_height);
    font-weight: 600;
}

.h1Text, h1 {
    font-size: var(--h1_size)
}

.h2Text, h2 {
    font-size: var(--h2_size);
}

.h3Text, h3 {
    font-size: var(--h3_size);
}

.h4Text, h4 {
    font-size: var(--h4_size);
}

.h5Text, h5 {
    font-size: var(--h5_size);
}

.captionText {
    display: block;
    font-size: var(--caption_size);
}

.smallText {
    display: block;
    font-size: var(--small_size);
}

a {
    color: var(--text_color);
    text-decoration: none;
}

a:focus,
a:hover {
    color: var(--text_hover);
}

ul {
    list-style: none;
}

svg,
img {
    width: 100%;
}

img {
    object-fit: cover;
}

.copy {
    cursor: copy;
}

.uc {
    text-transform: uppercase;
    letter-spacing: 0.02em;
}

.link {
    font-weight: 600;
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
}

.screenReaderOnly {
    height: 0;
    width: 0;
    overflow: hidden;
    position: absolute;
}

.divider {
    display: block;
    height: 1px;
    background-color: var(--bg2);
    margin: var(--gap_min) 0;
}

hr {
    border: none;
    border-top: 1px solid var(--bg3);
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    --color: var(--primary);
    --bg: var(--bg2);
    --speed: 1.5s;
    --size: 80px;
}

.loader {
    font-size: var(--size) !important;
    line-height: 1em !important;
    height: 1em !important;
    width: 1em !important;
    border-radius: 50% !important;
    border: 0.15em solid var(--bg) !important;
    border-top: 0.15em solid var(--color) !important;
    animation: spinAnimation var(--speed) linear infinite !important;
}

@keyframes spinAnimation {
    to {
        transform: rotate(360deg);
    }
}

.hasTooltip {
    position: relative;
}

.hasTooltip::after {
    content: "";
    position: absolute;
    opacity: 0;
    transition: opacity 0.2s ease-in-out 0.5s;
}

.hasTooltip:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: calc(100% + 0.5em);
    right: 0;
    opacity: 1;
    background-color: hsl(0, 0%, 11%);
    color: hsl(0, 0%, 95%);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2em;
    padding: 0.4em 0.6em;
    border-radius: var(--radius);
    box-shadow: var(--box_shadow);
    text-align: left;
    width: max-content;
    max-width: 200px;
}

.hasTooltip.ttl:hover::after {
    right: auto;
    left: 0;
}

.hasTooltip.ttc:hover::after {
    right: 50%;
    transform: translateX(50%);
}

.bold {
    font-weight: 600;
}

.block {
    display: block;
}

.padding {
    padding: var(--padding);
}

.padding_btn {
    padding: var(--padding_btn);
}

.lineClamp {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/*Form*/

form {
    display: flex;
    flex-direction: column;
}

textarea,
select,
input:is([type="tel"],
    [type="text"],
    [type="email"],
    [type="number"],
    [type="select"],
    [type="password"]) {
    line-height: 1em;
    width: 100%;
    background: none;
    outline: none;
    font-size: var(--body_size);
    padding: var(--padding);
    border: solid 1px var(--bg4);
    border-radius: var(--radius);
    color: var(--text_color);
}

select:focus,
textarea:focus,
input:is([type="tel"],
    [type="text"],
    [type="email"],
    [type="number"],
    [type="select"],
    [type="password"]):focus {
    border-color: var(--primary);
    outline: solid 1px var(--primary);
}

select option {
    color: #000;
}

textarea {
    resize: none;
}

form p {
    position: relative;
}

.inputOptions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    margin: 5px 0;
}

.inputOptions>div {
    position: relative;
}

.inputOptions input {
    position: absolute;
    opacity: 0;
    /* visibility: hidden;   */
}

.inputOptions input+label {
    display: block;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: var(--radius);
    background-color: var(--bg1);
    transition: all 0.2s;
}

.inputOptions input:focus+label,
.inputOptions input:hover+label {
    background-color: var(--bg2);
}

.inputOptions input:checked+label {
    color: #ffffff;
    background-color: var(--text_color);
}




/*Elements*/
/* button {
    border: none;
    cursor: pointer;
    color: var(--text_color);
    background-color: var(--bg);
    border-radius: var(--radius);
    font-family: var(--paragraph_font);
    font-size: var(--body_size);
} */

.styledButton {
    outline: none;
    cursor: pointer;
    width: 100%;
    line-height: 1em;
    color: #fff;
    background-color: var(--primary);
    border: solid 1px var(--primary);
    font-size: var(--body_size);
    padding: var(--padding);
    border-radius: var(--radius);
    font-weight: 600;
}

.inlineButton {
    background: none;
    margin-left: 6px;
}

.inlineButton:hover {
    color: var(--primary);
}

/*Card*/
.cardStyle {
    background-color: var(--bg);
    box-shadow: var(--card_box_shadow);
    border-radius: var(--radius);
    padding: var(--gap_min);
}

/*Style Classes*/
.fullHeight {
    min-height: 100vh;
}

.grid {
    display: grid;
}

.flex {
    display: flex;
    gap: var(--gap);
}

.flex.flexCenter {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.col-auto, .col-1, .col-2, .col-3, .col-4, .col-5 {
    display: grid !important;
    gap: var(--gap);
}

.col-auto {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.col-2 {
    grid-template-columns: repeat(2, 1fr);
}

.col-3 {
    grid-template-columns: repeat(3, 1fr);
}

.col-4 {
    grid-template-columns: repeat(5, 1fr);
}

.col-5 {
    grid-template-columns: repeat(5, 1fr);
}

@media only screen and (max-width: 800px) {

    .col-2,
    .col-3,
    .col-4 {
        grid-template-columns: repeat(2, 1fr);
    }

    .col-5 {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 600px) {

    .col-2,
    .col-3 {
        grid-template-columns: repeat(1, 1fr);
    }

    .col-4,
    .col-5 {
        grid-template-columns: repeat(2, 1fr);
    }
}

.stickyOptions {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
    margin: var(--gap);
}