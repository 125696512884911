.inputWrapper.hasAfterText .afterText {
    position: absolute;
    top: 1px;
    right: 1px;
    display: flex;
    align-items: center;
    padding: 0 9px;
    height: calc(100% - 2px);
    background-color: var(--bg1);
    border-radius: 0 var(--radius) var(--radius) 0;
    border-left: solid 1px var(--bg3);
}