.colorPaletteEditor label {
    width: 100%;
    height: 100%;
}

.colorPaletteEditor label input {
    visibility: hidden;
    height: 3em;
}

.colorPaletteEditor .card__hero {
    border-radius: var(--radius);
}

.colorPaletteEditor .card__content {
    position: absolute;
    bottom: 0;
    left: 0;
    line-height: 1.2em;
}

.colorPaletteEditor .card__content p {
    font-size: 1.5em;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 0.3em;
}