.folderView.list {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
}

.folderView.list .card {
    display: grid;
    grid-template-columns: 30px 1fr auto;
    gap: var(--gap_min);
    align-items: center;
}

.folderView.list .card .card__hero {
    margin: var(--padding_btn);
    overflow: hidden;
    border-radius: 3px;
}

.folderView.list .card .buttonGroup {
    position: relative;
    top: 0;
    right: 0;
    visibility: visible;
    background-color: var(--bg);
}

.uploadqueue {
    position: fixed;
    bottom: 0;
    right: 0;
}

.uploadqueue>.button {
    z-index: 1;
    font-size: 10px;
    background-color: var(--bg);
    top: 4px;
    left: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    --hover: #fff;
    --hover_bg: var(--error);
}

.uploadqueue:hover>.button {
    opacity: 1;
}

.uploadqueue li {
    width: 180px;
    margin: 10px;
    position: relative;
    border-radius: var(--radius);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    background-color: var(--bg);
}

.uploadqueue li p {
    padding: 10px 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.uploadqueue li:hover p {
    padding: 10px 30px 10px 12px;
}

.uploadqueue li .button {
    position: absolute;
    top: 6px;
    right: 10px;
    opacity: 0.2;
}

.uploadqueue li .button:hover {
    opacity: 1;
}

.uploadqueue .progress {
    border-radius: 0 0 var(--radius) var(--radius);
}