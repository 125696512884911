.gradientEditor .preview {
    display: block;
    width: 100%;
    height: 100%;
}

.gradientEditor .angleSlider output span:last-child::after {
    content: "deg";
}

.gradientEditor .stops {
    position: relative;
    margin-bottom: 10px;
}

.gradientEditor.card .track {
    display: block;
    height: 8px;
    width: 100%;
    border-radius: 5px;
    cursor: crosshair;
}

.gradientEditor.card .stop {
    display: block;
    position: absolute;
    top: 4px;
    transform: translateX(-50%) translateY(-50%);
    height: 20px;
    width: 20px;
    border-radius: 10px;
    box-shadow: 0 0 0 2px #f8f8f8,
        0 0 15px 2px rgba(0, 0, 0, 0.1);
}

.gradientEditor.card .stop input {
    height: 0;
    width: 0;
    visibility: hidden;
}

.gradientEditor.card .stop .buttonGroup {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1px;
    --radius: 5px;
    background-color: var(--bg);
    border-radius: var(--radius);
    position: absolute;
    top: -120%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

.gradientEditor.card .stop:hover .buttonGroup {
    opacity: 1;
    visibility: visible;
}

.gradientEditor.card .stop.isDragging:hover .buttonGroup,
.gradientEditor.card .stop .buttonGroup {
    opacity: 0;
    visibility: hidden;
}


.gradientEditor.card .stop .buttonGroup:after {
    border-color: var(--bg);
    border-bottom: 0 solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid transparent;
    border-top-color: var(--bg);
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: calc(50% - 5px);
    height: 0;
    width: 10px;
}

.gradientEditor.card .stop .buttonGroup .button {
    font-size: 12px;
}

.gradientEditor.card .stop .buttonGroup .button:first-child {
    border-radius: var(--radius) 0 0 var(--radius);
}

.gradientEditor.card .stop .buttonGroup .button:last-child {
    border-radius: 0 var(--radius) var(--radius) 0;
}

.gradientEditor.card .stop .buttonGroup label {
    position: relative;
}

.gradientEditor.card .stop .buttonGroup label input {
    position: absolute;
    height: 0;
    width: 0;
}

/* .gradientEditor.card label+.removeButton {
    position: absolute;
    top: -28px;
    left: -1px;
}

.gradientEditor.card label.isDragging+.removeButton {
    visibility: hidden;
    opacity: 0;
} */



/* .gradientEditor.card .content {
    gap: 0;
    padding: 5px 15px 15px 15px;
}

.gradientEditor.card .editor {
    margin: 15px 0;
    position: relative;
}

.gradientEditor.card .editor div {
    position: absolute;
    top: 4px;
    transform: translateX(-50%) translateY(-50%);
}

.gradientEditor.card input[type="color"] {
    position: absolute;
    visibility: hidden;
    height: 20px;
    width: 20px;
}

.gradientEditor.card label {
    display: block;
    cursor: pointer;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    box-shadow: 0 0 0 2px #f8f8f8,
        0 0 15px 2px rgba(0, 0, 0, 0.1);
    transform: scale(0.9);
    transition: all 0.2s;
}

.gradientEditor.card label:hover {
    transform: scale(1);
}

.gradientEditor.card label.isDragging {} */