.progress {
    display: block;
    height: 8px;
    width: 100%;
    background-color: var(--bg1);
    border-radius: var(--radius);
    overflow: hidden;
}

.progress div {
    position: relative;
    display: block;
    height: 100%;
}

.progress.true div {
    background-color: var(--primary);
}

.progress.complete div {
    background-color: var(--success);
}

.progress.false div {
    width: 20% !important;
    background-color: var(--bg3);
    animation: progressBarAnimation 1s ease-in-out infinite alternate;
}

.progress.error div {
    background-color: var(--error);
}

@keyframes progressBarAnimation {
    form {
        margin-left: 0;
    }

    to {
        margin-left: 80%;
    }
}