.card.color .card__hero {
    flex-direction: column;
}

.card.color .preview {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.6em;
    padding: var(--padding);
    line-height: 1;
    justify-content: flex-end;
    align-items: flex-start;
    cursor: auto;

}

.card.color .variations {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(8, 1fr);
    border-radius: 0 0 var(--radius) var(--radius);
    overflow: hidden;
}

.card.color .variations span {
    display: block;
    width: 100%;
    aspect-ratio: 1;
}

.card.color .card__content {
    display: none;
}