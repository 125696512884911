ul.limits {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5em
}

ul.limits li {
    background-color: var(--bg1);
    font-weight: 400;
    padding: 0.2em 0.4em;
    border-radius: 0.3em;
}