.content {
    display: flex;
    flex-direction: column;
    gap: 0.8em;
}

.content a {
    font-weight: 600;
}

.content.cardStyle {
    gap: 0;
}

.content.cardStyle>.heading {
    margin: 0;
}