/*Pages*/
.page {
    padding: var(--gap);
    min-height: 100vh;
    max-width: 800px;
    margin: 0 auto;
}

.page .pageHeader {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;
}

.pageTitle .captionText {
    z-index: 1;
    position: relative;
    line-height: 1.4em;
}

.pageTitle h1 {
    z-index: 0;
    position: relative;
}

.page .pageBody {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
}