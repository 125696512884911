.styledForm,
.styledForm .col {
    gap: 12px;
}

.styledForm ::placeholder {
    opacity: 0;
}

.styledForm .inputWrapper {
    position: relative;
}

.styledForm .smallText {
    margin-top: -0.6em;
}

.styledForm fieldset {
    margin: 0;
    padding: 0;
    border: none;
    display: flex;
    flex-direction: column;
    gap: var(--gap_min);
}

.styledForm fieldset legend {
    font-size: var(--h4_size);
    font-weight: 600;
    margin-bottom: 10px;
}

.styledForm select+label,
.styledForm textarea+label,
.styledForm input:is([type="tel"],
    [type="text"],
    [type="email"],
    [type="number"],
    [type="select"],
    [type="password"])+label {
    color: var(--text_color);
    position: absolute;
    cursor: text;
    line-height: 1em;
    font-size: 1rem;
    top: 11px;
    left: 13px;
    transition: all 0.2s;
}

.styledForm select+label,
.styledForm textarea:autofill+label,
.styledForm textarea:-webkit-autofill+label,
.styledForm textarea:focus+label,
.styledForm input:is([type="tel"],
    [type="text"],
    [type="email"],
    [type="number"],
    [type="select"],
    [type="password"]):is(:not(:placeholder-shown), :focus)+label {
    font-size: 0.6rem;
    top: -4px;
    padding: 0 3px;
    background-color: var(--bg);
}