.dropZone label {
    height: 250px;
    width: 100%;
    padding: var(--gap);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    color: #cccccc;
    border: 1px dashed var(--border_color);
    border-radius: var(--radius);
}

.dropZone label:hover,
.dropZone label:focus,
.dropZone label:focus-within,
.dropZone.active label {
    border-style: solid;
    border-color: var(--primary);
    box-shadow: inset 0 0 0 1px var(--primary);
}

.dropZone label div {
    width: 100%;
    height: auto;
    position: relative;
}

.dropZone svg {
    height: auto;
}

.dropZone input {
    height: 10px;
    width: 10px;
    position: absolute;
    opacity: 0;
}