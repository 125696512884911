.projectMenuItem,
.projectSubMenu li {
    position: relative;
}

.addFolderFolder button,
.projectMenuItem>button.button {
    position: absolute;
    font-size: 11px;
    padding: 3px;
    top: 3px;
    right: 5px;
}

.projectMenuItem>button.button {
    font-size: 0.9em;
}

.projectMenuItem>a.button .projectIcon {
    display: inline-block;
    height: 2.4em;
    aspect-ratio: 1;
    border-radius: 0.3em;
    text-align: center;
    font-size: 0.5em;
    line-height: 2.5em;
    font-weight: 600;
    letter-spacing: 0.06em;
    text-transform: uppercase;
}

.projectMenuItem>a.active+button.button,
.projectMenuItem>a:hover+button.button {
    background-color: var(--bg1);
}

.projectMenuItem>button.button .icon {
    transform: rotate(-90deg);
}

.projectMenuItem.active>button.button .icon {
    transform: rotate(0deg);
}

.projectMenuItem.active>button.button,
.projectMenuItem.active>a.button {
    background-color: var(--bg1);
}

.projectMenuItem .projectSubMenu {
    --body_size: 14px;
    overflow: hidden;
    height: 0;
    padding-left: 5px;
    margin: 0 0 0 2px;
    transition: all 0.2s;
    border-left: 2px solid var(--bg1);
}

.projectMenuItem .navLinkButton {
    width: 100%;
}

.projectMenuItem .navLinkButton.active+.projectSubMenu {
    height: auto;
    transition: all 0.2s;
    margin: 5px 0 var(--gap_min) 2px;
}

.addFolderFolder {
    padding: 0;
    position: relative;
}

.addFolderFolder input::placeholder {
    color: var(--text_color);
    opacity: 1;
}

.addFolderFolder input {
    color: var(--text_color);
    line-height: 1.2;
    padding: 5px 7px;
    border: none;
    opacity: 0.5;
}

.addFolderFolder input:focus {
    box-shadow: none;
    background-color: var(--bg1);
    opacity: 1;
}