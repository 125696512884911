.svgEditor {
    box-shadow: var(--box_shadow);
    border-radius: var(--radius);
    padding: var(--padding);
    background-color: var(--bg);
    position: relative;
}

.svgEditor .svgContainer {
    aspect-ratio: 1;
    width: 100%;
}

.svgEditor .svgOptions {
    display: flex;
    flex-direction: row;
    gap: var(--gap_min);
    border-top: solid 1px var(--bg2);
    padding-top: var(--gap_min);
    justify-content: space-between;
}

.svgEditor .svgColors .list,
.svgEditor .svgColorOptions {
    display: flex;
    flex-direction: row;
    gap: var(--gap_min);
}

.svgEditor .svgColorOptions button.removeButton {
    opacity: 1;
    visibility: visible;
    position: absolute;
    top: -5px;
    right: -5px;
    height: 20px;
    width: 20px;
}

.changeColor {
    box-shadow: var(--box_shadow);
    border-radius: var(--radius);
    padding: var(--padding);
    background-color: var(--bg);
    position: absolute;
    bottom: -70px;
    left: 0;
    display: flex;
    flex-direction: row;
    gap: var(--gap_min);
}

.svgEditor .svgColors>p {
    width: 100%;
}

.verticalDivider {
    border-left: solid 1px var(--bg2);
    height: 100%;
}

.svgEditor .svgColor button {
    display: block;
    width: 40px;
    height: 40px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.15);
    position: relative;
}

.svgEditor .svgColor button.active,
.svgEditor .svgColor button:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.colorOption.selected::before {
    content: "\f10d";
    font-family: "BLC Icons" !important;
    font-size: 20px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    mix-blend-mode: exclusion;
}