.projectSummery {
    display: grid;
    gap: var(--gap);
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.logosPreview {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 0;
    background-color: #fff;
    box-shadow: var(--box_shadow);
    border-radius: var(--radius);
    overflow: hidden;
}

.logosPreview :nth-child(1) {
    grid-column: 1/2;
    grid-row: 2/4;
}

.logosPreview :nth-child(2) {
    grid-column: 2/3;
    grid-row: 2/3;
    background-color: #000;
}

.logosPreview :nth-child(3) {
    grid-column: 2/3;
    grid-row: 3/4;
    background-color: #f3f3f3;
}

.logosPreview img {
    width: 100%;
    height: auto;
    object-fit: contain;
    aspect-ratio: 1;
    padding: 15%;
}

.colorsPreview {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0;
    overflow: hidden;
    border-radius: var(--radius);
    box-shadow: var(--box_shadow);

}

.colorsPreview .color {
    height: auto;
    width: 100%;
    /* flex: 1 1 30px; */
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.colorsPreview .color .colorDetails {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    height: 10em;
    padding: var(--gap_min);
    overflow: hidden;
    width: 0px;
    opacity: 0;
    visibility: hidden;
    transition: width 0.3s ease,
        opacity 0.2s ease 0.3s;
}

.colorsPreview .color .colorDetails h5 {
    margin-bottom: 0.5em;
}

.colorsPreview .color:hover .colorDetails {
    width: 240px;
    opacity: 1;
    visibility: visible;
}

.gradientPreview {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0;
    overflow: hidden;
    border-radius: var(--radius);
    box-shadow: var(--box_shadow);
}


.gradientPreview .gradient {
    display: block;
    height: auto;
    width: 100%;
    min-height: 230px;
    transition: all 0.3s ease-in-out;
}