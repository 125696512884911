.button {
    border: none;
    cursor: pointer;
    border-radius: var(--radius);
    font-family: var(--paragraph_font);
    font-size: var(--body_size);
    line-height: 1em;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.4em;
    padding: var(--padding_btn);
    color: var(--text_color);
    background-color: var(--bg);
    position: relative;
    --hover: var(--text_hover);
    --hover_bg: var(--bg1);
}

.button.reverse {
    flex-direction: row-reverse;
}

.button .icon {
    font-size: 1.2em;
    pointer-events: none;
}

.button img.imageIcon {
    width: 1.2em;
    height: 1.2em;
    object-fit: contain;
    padding: 0.15em;
    border-radius: 0.1em;
}

.button .text {
    line-height: 1.2em;
    text-transform: capitalize;
    pointer-events: none;
}

.button.true,
.button.active,
.button:focus,
.button:hover {
    --text_color: var(--hover);
    background-color: var(--hover_bg) !important;
}

.button:focus {
    outline: none;
}

.button.floating {
    position: absolute;
    border-radius: 3px;
    --gap: 10px;
}

.button.floating.top_left {
    top: var(--gap);
    left: var(--gap);
}

.button.floating.top_right {
    top: var(--gap);
    right: var(--gap);
}

.button.floating.bottom_left {
    bottom: var(--gap);
    left: var(--gap);
}

.button.floating.bottom_right {
    bottom: var(--gap);
    right: var(--gap);
}


.button.green {
    --text_color: #fff;
    --bg: var(--success);
    --hover: #fff;
    --hover_bg: var(--success);
}

/*Remove button*/
.button.red,
.button.remove {
    --text_color: #fff;
    --bg: var(--error);
    --hover: #fff;
    --hover_bg: var(--error);
}

.floatingContainer {
    position: relative;
}

.floatingContainer .floating.hidden,
.floatingContainer>.floating {
    filter: saturate(0.2);
    opacity: 0;
}

.floatingContainer:hover .floating.hidden,
.floatingContainer:hover>.floating {
    opacity: 0.15;
}

.floatingContainer:hover .floating.hidden:hover,
.floatingContainer:hover>.floating:hover {
    opacity: 1;
    filter: saturate(1);
}

.floatingContainer>.floating.remove {
    font-size: 0.65em;
    top: 0.4em;
    right: 0.5em;
    border-radius: 0.3em;
}

.button.close {
    --text_color: var(--text_color);
    --bg: transparent;
    --hover: var(--text_color);
    --hover_bg: transparent;
    box-shadow: none;
}

.button.styled {
    text-align: center;
    justify-content: center;
    font-weight: 600;
    --text_color: var(--bg);
    background-color: var(--primary);
    --hover_bg: var(--primary);
    padding: 0.7em 1.2em;
}

.button.floating.close {
    top: 0;
    right: 0;
    font-size: 14px;
    opacity: 0.5;
}

.button.floating.close:hover {
    opacity: 1;
}

.button.radio input[type="radio"] {
    display: none;
}

.buttonGroup {
    display: flex;
    flex-direction: row;
    gap: var(--gap_min);
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}

.buttonGroup.left {
    justify-content: flex-start;
}

.center .button {
    justify-content: center;
}

.button.inline {
    display: inline-flex;
    background-color: var(--bg1);
    transform: translateY(10%);
    gap: 0;
}

.button.inline .icon {
    opacity: 0;
    width: 0;
}

.button.inline:hover {
    gap: 0.4em;
}

.button.inline:hover .icon {
    opacity: 1;
    width: auto;
}

.button .loading {
    height: auto;
    --size: 1em;
    --bg: var(--text_color);
}

.button:disabled {
    opacity: 0.5;
    pointer-events: none;
}

.button.isLoading:disabled {
    opacity: 1;
}