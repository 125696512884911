.heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: var(--gap_min);
    margin-bottom: var(--gap_min);
}

.list+.heading,
.content+.heading {
    margin-top: var(--gap);
}