.imageBlock img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    padding: 12%;
    margin: 0;
}

.imageBlock .button.floating {
    top: var(--gap_min);
    right: var(--gap_min);
}