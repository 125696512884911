ul.list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--gap_min);
}

ul.list li {
    width: 100%;
    background-color: var(--bg1);
    padding: 5px 5px 5px 10px;
    border-radius: var(--radius);
    display: grid;
    gap: var(--gap_min);
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    align-items: center;
    justify-items: stretch;
}

ul.list li:first-child {
    margin-top: 5px;
}

ul.list li .button {
    --bg: var(--bg1);
    --hover_bg: var(--bg2);
}

ul.list.compact {
    gap: 0;
}

ul.list.compact li {
    width: 100%;
    background-color: var(--bg);
    padding: 5px 0;
    border-top: solid 1px var(--bg1);
}


ul.list.compact li .button {
    --bg: var(--bg);
    --hover_bg: var(--bg1);
}

.cardStyle .heading {
    margin-bottom: 0;
}

.cardStyle .list+.heading h4 {
    font-size: var(--h5);
}