.imageEditor {
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: var(--gap);
}

.imageEditor .preview {
    aspect-ratio: 1;
    background-color: var(--bg1);
    width: 600px;
}

.imageEditor .preview img {
    width: 100%;
    height: auto;
    object-fit: contain;
    aspect-ratio: 1/1;
}

.imageEditor .filters {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--gap_min);
}