.fontSelectContainer {
    display: flex;
    flex-direction: column;
    gap: var(--gap_min)
}

.fontSelectContainer+.fontSelectContainer {
    margin-top: var(--gap_min)
}

.fontSelectContainer.select-heading h2 {}

.fontSelectContainer.select-sub-heading h2 {
    font-size: var(--h3_size);
}

.fontSelectContainer.select-body h2 {
    font-size: var(--h4_size);
}