.userSection {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: relative;
}

.userSection .profileIcon {
    display: block;
    height: 35px;
    width: 35px;
    position: relative;
    border-radius: var(--radius);
    background-color: var(--primary);
    text-transform: uppercase;
    --text_color: #fff;

    --hover: #fff;
    --hover_bg: var(--primary);
}