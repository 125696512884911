.notifications {
    display: flex;
    flex-direction: column-reverse;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    --anim_func: cubic-bezier(.5, -0.5, .5, 1.5);
}


.notification {
    pointer-events: all;
    width: clamp(250px, 50vw, 300px);
    position: relative;
    padding: 15px;
    height: auto;
    margin-bottom: 15px;
    border-radius: var(--radius);
    background-color: var(--bg);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    animation: notificationSlideInOut 5s var(--anim_func) forwards;
}

.notification>.icon {
    height: 20px;
    width: 20px;
    font-size: 20px;
    margin-right: 10px;
    aspect-ratio: 1;
    color: var(--bg);
    background-color: var(--primary);
    border-radius: 50%;
    padding: 2px;
}

.notification p {
    font-size: 14px;
    line-height: 1.2em;
    padding-right: 20px;
}

.notification::after {
    content: "";
    height: 5px;
    width: 100%;
    background-color: var(--primary);
    position: absolute;
    left: 0;
    bottom: 0;
    animation: notificationBar 5s ease-in forwards;
    transform-origin: 0%;
}

.notification.error>.icon,
.notification.error::after {
    background-color: var(--error);
}

.notification.success>.icon,
.notification.success::after {
    background-color: var(--success);
}

.notification.loading>.icon,
.notification.loading::after {
    background-color: var(--bg3);
}

.notification.loading {
    animation: notificationSlideIn 0.5s var(--anim_func) forwards;
}

.notification.loading::after {
    animation: notificationBarLoading 1s ease-in-out infinite alternate;
}

@keyframes notificationSlideInOut {
    0% {
        height: 0;
        transform: translateX(100%);
        opacity: 0;
        margin-bottom: 0;
    }

    5% {
        height: auto;
        transform: translateX(150%);
        opacity: 0;
        margin-bottom: 15px;
    }

    10% {
        transform: translateX(0);
        opacity: 1;
    }

    90% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}

@keyframes notificationSlideIn {
    0% {
        height: 0;
        transform: translateX(100%);
        opacity: 0;
        margin-bottom: 0;
    }

    50% {
        height: auto;
        transform: translateX(150%);
        opacity: 0;
        margin-bottom: 15px;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes notificationSlideOut {
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    50% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}

@keyframes notificationBar {
    0% {
        transform: scaleX(1);
    }

    100% {
        transform: scaleX(0);
    }
}

@keyframes notificationBarLoading {
    0% {
        transform: scaleX(0.25) translateX(0);
    }

    100% {
        transform: scaleX(0.25) translateX(300%);
    }
}