.card {
    cursor: pointer;
    position: relative;
    box-shadow: var(--card_box_shadow);
    border-radius: var(--radius);
}

.card.selected,
.card:focus,
.card:hover {
    box-shadow: var(--card_hover_box_shadow);
}

.card .card__hero {
    aspect-ratio: 1;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: var(--radius) var(--radius) 0 0;
}

.card .card__hero img {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 1/1;
}

.card .card__content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: var(--padding);
}

.card .card__name {
    position: relative;
    font-weight: 600;
}

.card .card__options.buttonGroup {
    background-color: var(--bg2);
    padding: 0;
    gap: 1px;
    --radius: 5px;
    border-radius: var(--radius);
    position: absolute;
    top: -5px;
    right: -5px;
    visibility: hidden;
    box-shadow: var(--box_shadow);
    opacity: 0;
}

.card:focus .card__options.buttonGroup,
.card:hover .card__options.buttonGroup {
    opacity: 1;
    visibility: visible;
}


.card .card__options.buttonGroup .button {
    border-radius: 0;
    --hover_bg: var(--bg1);
}



.card .card__options.buttonGroup .button:first-child {
    border-top-left-radius: var(--radius);
    border-bottom-left-radius: var(--radius);
}

.card .card__options.buttonGroup .button:last-child {
    border-top-right-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
}