.simplecheckbox {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 1.6em;
}

.simplecheckbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.simplecheckbox span {
    position: absolute;
    top: 0.1em;
    left: 0;
    height: 1.2em;
    width: 1.2em;
    border-radius: 2px;
    background-color: var(--bg2);
}

.simplecheckbox:hover span {
    background-color: var(--bg3);
}

.simplecheckbox span::after {
    content: "";
    display: none;
    position: relative;
    left: 0.4em;
    top: 0.23em;
    width: 0.4em;
    height: 0.65em;
    border: solid white;
    border-width: 0 0.18em 0.18em 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.simplecheckbox input:checked~span {
    background-color: var(--primary);
}

.simplecheckbox input:checked~span::after {
    display: block;
}