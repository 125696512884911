.buttonGroup.tabs {
    justify-content: space-between;
    gap: 0;
    margin: 0 -3px;
}

.buttonGroup.tabs .button {
    flex-grow: 1;
    flex-basis: 1;
    justify-content: center;
    margin: 0 3px;
}

.buttonGroup.tabs .button::before {
    content: "";
    display: block;
    position: absolute;
    top: 10%;
    left: -3.5px;
    width: 1px;
    height: 80%;
    background: var(--bg1);
}

.buttonGroup.tabs .button:first-child::before {
    content: none;
}

.buttonGroup.tabs .button.active {
    background: var(--bg1);
    color: var(--text1);
}

.tabContainer {
    display: flex;
    gap: 0;
}

.tabContainer .tab.true {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s ease-in-out;
    width: 100%;
}

.tabContainer .tab.false {
    opacity: 0;
    visibility: hidden;
    width: 0;
}

pre {
    background: var(--bg1);
    padding: var(--gap_min);
    border-radius: var(--radius);
    height: 400px;
    overflow: auto;
    tab-size: 4;
}

code .button.floating {
    background-color: var(--bg1);
    --hover_bg: var(--bg);
}