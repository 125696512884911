.checkboxswitch {
    display: block;
    height: 1.8em;
    width: 3.6em;
    border: solid 0.15em var(--bg2);
    border-radius: 0.9em;
    cursor: pointer;
}

.checkboxswitch span {
    display: block;
    height: auto;
    width: 1.5em;
    aspect-ratio: 1;
    border: solid 0.1em var(--bg);
    background-color: var(--error);
    border-radius: 50%;
    margin-left: 1.8em;
    transition: all 0.2s ease-in-out;
}

.checkboxswitch:hover {
    border-color: var(--bg3);
}

.checkboxswitch input:checked+span {
    margin-left: 0;
    background-color: var(--success);
}

.checkboxswitch input {
    display: none;
}