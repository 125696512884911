.logoVariation.white .svgContainer,
.logoVariation.black .svgContainer {
    aspect-ratio: 1 / 1;
    border-radius: var(--radius);
}

.logoVariation.white .svgContainer {
    background-color: #000;
}

.generated.logoVariation {
    border: 1px dashed var(--border_color);
    border-radius: var(--radius);
    padding: var(--gap);
}

.createProject .dropZone label,
.createProject .svgContainer {
    height: auto;
    aspect-ratio: 1;
}

.createProject .button.active {
    --hover: var(--bg1);
    --hover_bg: var(--dc);
}

.createProject .buttonGroup {
    gap: 5px;
}