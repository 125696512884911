.navToggle {
    position: fixed;
    top: 18px;
    left: 235px;
    box-shadow: var(--box_shadow);
    transition: all 0.2s;
    z-index: 999;
    --hover_bg: var(--bg)
}

.hideNav .navToggle {
    left: -4px;
}

.hideNav .navToggle .icon {
    transform: rotate(180deg);
}

.headerBar {
    width: 95vw;
    max-width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    padding: 10px;
    transition: all 0.2s;
    z-index: 999;
    background-color: var(--bg);
    /* --bg: hsl(227 22% 20%);
    --bg1: hsl(227 22% 25%);
    --bg2: hsl(227 22% 30%);
    --text_color: hsl(227 22% 85%);
    --text_hover: hsl(227 22% 95%); */
    /* 
    --bg: hsl(0 0% 100%);
    --bg1: hsl(220, 100%, 96%);
    --bg2: hsl(220, 100%, 92%); */
}

.hideNav .headerBar {
    left: -275px;
}

.headerBar .addPadding,
.headerBar .headerLink,
.headerBar h5,
.headerBar .h5Text {
    display: block;
    position: relative;
    padding: var(--padding_btn);
}

.projectsSection {
    position: relative;
}

.projectsSection .buttonGroup {
    position: absolute;
    top: 4px;
    right: 5px;
}

.projectsSection .buttonGroup .button {
    font-size: 0.9em;
    padding: 3px;
    position: relative;
}

.headerBar nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.headerBar ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.headerBar .headerLink {
    border-radius: var(--radius);
    padding: 5px 10px 5px 32px;
}

.headerBar .headerLink:hover {
    background-color: var(--bg1);
}

.headerBar .headerLink.blci:before {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
}

.headerBar .messages {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.headerBar .message {
    padding: var(--padding_btn);
    background-color: var(--bg1);
}

.headerBar .message a {
    font-weight: 600;
}

.headerBar .message a:hover {
    text-decoration: underline;
}

/* #userSubMenu li h5 {
    margin: 5px 10px;
    padding-bottom: 0.3em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

#userSubMenu .logout,
#userSubMenu li a {
    width: 100%;
    display: block;
    padding: 5px 10px;
    border-radius: var(--radius);
    text-transform: capitalize;
}

#userSubMenu .logout:hover,
#userSubMenu li a:focus,
#userSubMenu li a:hover {
    background-color: var(--bg1);
}

#userSubMenu .logout {
    position: relative;
    text-align: left;
    padding: 5px 10px 5px 32px;
}

#userSubMenu .logout::before {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
} */