.fontPreview {
    overflow: hidden;
}

.fontsPreview:nth-child(1) {
    font-weight: 600;
}

.fontPreview .aa {
    width: 100%;
    display: block;
    font-size: 120px;
    line-height: 1em;
    color: var(--primary);
}

.fontPreview p {
    line-height: 1.2em;
    font-size: var(--h3_size);
    padding-bottom: var(--gap_min);
}

.fontPreview .fontExample {
    display: grid;
    line-height: 1.1em;
    font-size: var(--h4_size);
    grid-template-columns: repeat(auto-fill, minmax(1.5em, 1fr));
    gap: var(--gap_min)
}