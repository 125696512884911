.portalForm .logos {
    gap: var(--gap_min);
    padding-bottom: var(--gap);
}

.portalForm .colorPaletteEditors {
    gap: var(--gap_min);
    padding-bottom: var(--gap);
}

.portalForm .metaData {
    display: flex;
    flex-direction: row;
    gap: var(--gap_min);
}

.portalForm .metaData .brand-favicon {
    height: 88px;
    width: 88px;
}

.portalForm .metaData .brand-favicon label {
    height: 100%;
    padding: 10px;
}

.portalForm .metaData .metaText {
    display: flex;
    flex-direction: column;
    gap: var(--gap_min);
    flex: 1;
}