.clientOrg {}

/* .clientOrg .heading {
    margin: 0;
} */
/* 
.clientOrg .list+.heading.invites {
    margin-top: var(--gap_min);
} */

.clientOrg .heading.invites h4 {
    font-size: var(--h5);
}

.clientOrg .list li {}

/* .clientOrg+.clientOrg {
	border-top: solid 1px var(--bg2);
	padding-top: var(--gap);
} */