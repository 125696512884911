.inputWrapper.otp>div {
    display: grid;
    grid-template-columns: repeat(3, 1fr) 0.2fr repeat(3, 1fr);
    align-items: center;
    width: 100%;
    margin: 0 auto;
    gap: 8px;
}

.inputWrapper.otp>div>* {
    grid-row: 1/2;
}

.inputWrapper.otp>div span {
    grid-column: 4/5;
    text-align: center;
}

.inputWrapper.otp>div input {
    text-align: center;
}

.inputWrapper.otp>span {
    margin-top: 0.2em;
}