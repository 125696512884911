.projectBlock {
    scroll-snap-align: start;
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.projectBlock.boxed {
    padding: var(--gap);
}

.projectBlock.boxed,
.projectBlock .projectBlock__header {
    width: 100%;
    max-width: var(--max_width);
    margin: 0 auto;
}

.projectBlock .projectBlock__header {
    display: flex;
    flex-direction: row;
    gap: 0.4rem;
    align-items: center;
    justify-content: space-between;
    padding-bottom: var(--gap);

}

.projectBlock .projectBlock__header__content {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}

.projectBlock .projectBlock__content {}

.projectBlock .projectBlock__header,
.projectBlock .projectBlock__content {
    position: relative;
}