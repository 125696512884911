.ColorInput {
    position: relative;
    display: block;
    height: 3em;
    width: auto;
    aspect-ratio: 1;
    border-radius: var(--radius);
}


.ColorInput input {
    height: 0;
    width: 0;
    visibility: hidden;
}