.textScalePreview {
    display: flex;
    flex-direction: column;
    padding-top: var(--gap);
    gap: var(--gap_min);
}


.textScalePreview .smallText {
    display: block;
    margin: 0 !important;
    line-height: 1em;
}

.textScalePreview .item-0 p,
.textScalePreview .item-1 p,
.textScalePreview .item-2 p {
    font-family: var(--heading_font);
    font-weight: var(--heading_font_weight);
    font-style: var(--heading_font_style);
    line-height: 1.2;
}

.textScalePreview .item-3 p,
.textScalePreview .item-4 p {
    font-family: var(--subheading_font);
    font-weight: var(--subheading_font_weight);
    font-style: var(--subheading_font_style);
    line-height: 1.4;
}

.textScalePreview .item-5 p,
.textScalePreview .item-6 p,
.textScalePreview .item-7 p {
    font-family: var(--body_font);
    font-weight: var(--body_font_weight);
    font-style: var(--body_font_style);
}