.card.gradient .card__hero {
    flex-direction: column;
}

.card.gradient .preview {
    width: 100%;
    height: 100%;
}

.card.gradient .variations {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(8, 1fr);

}

.card.gradient .variations span {
    display: block;
    width: 100%;
    aspect-ratio: 1;
}

.card.gradient .card__content {
    border-radius: 0 0 var(--radius) var(--radius);
    overflow: hidden;
    flex-direction: row;
    flex-wrap: wrap;
}

.card.gradient .card__content p {
    text-transform: uppercase;
    flex: 1 1 80px;
    border-radius: 3px;
}


.card.gradient .card__content p span {
    display: inline-block;
    height: 1em;
    width: 1em;
    margin-right: 0.2em;
    margin-bottom: -0.1em;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}