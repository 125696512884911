.fileUpload {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 10px;
    border: solid 1px var(--border_color);
    border-radius: var(--radius);
    color: var(--text_color);
    padding: var(--padding);
}

.fileUpload:hover,
.fileUpload:focus-within {
    border-color: var(--primary);
    box-shadow: 0 0 0 1px var(--primary);
}

.fileUpload input {
    position: absolute;
    height: 2px;
    width: 2px;
    opacity: 0;
}

.fileUpload .icon {
    font-size: 1.8em;
    margin: -0.1em 0;
}