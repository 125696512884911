.rangeSlider {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.rangeSlider input {
    -webkit-appearance: none;
    appearance: none;
    height: 0.2em;
    width: 100%;
    --pos: calc((var(--value) - var(--min)) / (var(--max) - var(--min)) * 100%);
    background-image: linear-gradient(to right,
            var(--dc3) 0%,
            var(--dc3) var(--pos),
            var(--bg2) var(--pos),
            var(--bg2) 100%);
    border-radius: 0.25em;
    cursor: pointer;
    outline: none;
}

.rangeSlider input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0.8em;
    height: 0.8em;
    background-color: var(--dc);
    border-radius: 50%;
    cursor: pointer;
}

.rangeSlider input::-moz-range-thumb {
    width: 0.8em;
    height: 0.8em;
    background-color: var(--dc);
    border-radius: 50%;
    cursor: pointer;
}

.rangeSlider output {
    font-size: 1em;
    line-height: 1em;
    display: flex;
    justify-content: space-between;
}