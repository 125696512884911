.brandGuidePage {
    padding: 0;
    max-width: 100%;

}

.brandGuidePage .pageHeader {
    display: none;
}

.brandGuidePage .pageBody {
    scroll-snap-type: y mandatory;
}

.centeredLogo .projectBlock__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.centeredLogo .imageBlock {
    width: 100%;
    height: auto;
    max-width: 800px;
    max-height: 800px;
}

/* .brandGuidePage .cover {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
} */

/*

.brandGuidePage .cover .svgContainer {
    width: 80%;
    max-width: 800px;
}

.brandGuidePage .cover .svgContainer div {
    width: 100%;
    max-width: 800px;
}

.brandGuidePage .containWidth,
.brandGuidePage section {
    max-width: var(--max_width);
    padding: var(--gap);
    margin: auto;
}

.brandGuidePage .sectionTitle {
    padding-top: 20px;
    padding-bottom: 10px;
}

.brandGuidePage .sectionDescription {
    padding: 0 0 10px 0;
}

.brandGuidePage section.fullWidth {
    max-width: 100%;
    padding: 0;
}

section.fullHeight>.col-2,
section.fullHeight {
    gap: 0;
    height: 100vh;
}

section.logoVariations .col-2,
section.logoMarkVariations .col-2 {
    --offset: var(--h2_size);
    height: calc(100% - var(--offset));
}

.variation.primaryBG {
    background-color: var(--primary);
}

.variation.white {
    background-color: #000;
}*/


.logoVariations .projectBlock__content {
    gap: 0;
}

.logoVariations .imageBlock {
    aspect-ratio: 4/3;
}

.logoVariations .imageBlock:nth-child(2) {
    background-color: var(--primary);
}

.logoVariations .imageBlock:nth-child(3) {
    background-color: #000;
}

/*Safe Zone*/
.safeZone .safeZoneContainer {
    --color: #c7c7c7;
    margin: 0 auto;
    width: auto;
    position: relative;
    max-width: 400px;
    border: dashed 1px var(--color);
    --zone: 40px;
}

.safeZone .safeZoneContainer div {
    width: 100%;
    max-width: 100%;
}

.safeZone .safeZoneContainer::before,
.safeZone .safeZoneContainer::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: dashed 1px var(--color);
}

.safeZone .safeZoneContainer::before {
    height: calc(100% - 2 * var(--zone));
    top: var(--zone);
    border-width: 1px 0;
}

.safeZone .safeZoneContainer::after {
    width: calc(100% - 2 * var(--zone));
    left: var(--zone);
    border-width: 0 1px;

}

.safeZone img {
    display: block;
    padding: 0;
    margin: var(--zone);
    width: auto;
}

.safeZone .safeZoneSpacer::before,
.safeZone .safeZoneSpacer::after,
.safeZone .safeZoneSpacer div::before,
.safeZone .safeZoneSpacer div::after {
    content: "X";
    color: #000;
    position: absolute;
    display: block;
    font-size: 10px;
    width: var(--zone);
    text-align: center;
    aspect-ratio: 1/1;
    line-height: var(--zone);
}

.safeZone .safeZoneSpacer::before {
    top: 0;
    left: 0;
}

.safeZone .safeZoneSpacer::after {
    top: 0;
    right: 0;
}

.safeZone .safeZoneSpacer div::before {
    bottom: 0;
    left: 0;
}

.safeZone .safeZoneSpacer div::after {
    bottom: 0;
    right: 0;
}

/*Use of Logo*/
.useOfLogo .col {
    gap: calc(var(--gap) * 2);
}

.useOfLogo .col p::before {
    display: inline-block;
    font-size: 1.4em !important;
}

.useOfLogo .col>div p {}

.useOfLogo .col>div p span {
    display: block;
    position: relative;
    color: var(--error);
    font-weight: 600;
    font-size: 0.8em;
}

.useOfLogo .col>div p span::before {
    content: "+";
    transform: rotate(45deg);
    position: absolute;
    left: -1em;
}

.useOfLogo .col>div .svgContainer {
    margin-top: var(--gap);
    justify-content: flex-start;
}

.useOfLogo .col>div .svgContainer div {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 120px;
}

.useOfLogo .col>div .svgContainer svg {
    padding: 0;
}

.useOfLogo .col .color svg {
    filter: hue-rotate(45deg);
}

.useOfLogo .col .skew svg {
    transform: scaleX(0.86) translatex(-7%);
}

.useOfLogo .col .rotate svg {
    transform: rotate(-5deg);
}

.useOfLogo .col .bg svg {
    filter: brightness(1.1);
    transform: scale(0.8);
}

.useOfLogo .col .bg .svgContainer {
    background-color: var(--primary);
}

.useOfLogo .col .shadow svg {
    filter: drop-shadow(0 5px 2px rgba(0, 0, 0, 0.5));
}

.useOfLogo .col .filter svg {
    filter: hue-rotate(45deg) invert(0.7);
}

/**/
@media print {

    .ReactQueryDevtools,
    .navToggle,
    header {
        display: none !important;
    }

    main {
        margin-left: 0 !important;
    }

    main * {
        page-break-after: avoid;
        page-break-before: avoid;
        page-break-inside: avoid;
    }

    main>section {
        min-height: 100vh;
    }

    main>section .sectionTitle,
    main>section {
        page-break-before: always;
    }
}


@media print {
    html {
        -webkit-print-color-adjust: exact
    }

    body {
        margin: 0
    }

    .section {
        padding: 0;
        display: flex;
        flex-direction: column;
        border: 0 !important;
        -moz-column-break-inside: avoid;
        break-inside: avoid
    }

    .print-full-page {
        width: 100vw;
        height: 100vh;
        page-break-after: always;
        -moz-column-break-after: always;
        break-after: always
    }

    .print-no-space {
        padding: 0;
        margin: 0
    }

    .print-break {
        page-break-after: always;
        -moz-column-break-after: always;
        break-after: always
    }

    .section__header {
        margin: 0;
        background-color: var(--brand-primary);
        height: 100vh;
        padding: 32px;
        display: flex;
        flex-direction: column;
        justify-content: center
    }

    .primary-is-dark .section__header {
        color: #fff
    }

    .section__header .section__text {
        margin: 0
    }

    .section--logo .section__header {
        margin-bottom: 0
    }

    .section__title {
        margin-bottom: 24px;
        margin-right: 32px
    }

    .section__body {
        padding: 24px
    }

    .section__title-wrap {
        margin: 0
    }

    .logos__item {
        min-height: 44vh;
        max-height: 50vh
    }

    .color-container {
        -moz-column-break-inside: avoid;
        break-inside: avoid;
        -moz-column-break-after: page;
        break-after: page;
        display: block;
        margin: 0;
        margin-top: 20px;
        width: 100%;
        height: calc(50vh - 60px);
        display: flex;
        flex-direction: column
    }

    .colors__item {
        flex-grow: 2
    }

    .color-container .colors__shade {
        height: 32px
    }

    .color-container, .logo-prohibited>* {
        float: left;
        margin-right: 3%
    }

    .grab-code, .print-hide {
        display: none
    }

    .print-block {
        display: block !important
    }

    .shadow-1, .shadow-3 {
        box-shadow: none;
        border: 1px solid #dfdfdf
    }

    .typescale {
        padding-bottom: 0
    }

    .typescale__text {
        margin-bottom: 4px !important
    }

    .typescale__meta {
        margin: 0 !important
    }
}