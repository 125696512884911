.contextMenu {
    --width: 180px;
    position: absolute;
    z-index: 1000;
    height: auto;
    width: 100%;
    max-width: var(--width);
    display: flex;
    flex-direction: column;
    background-color: var(--bg);
    padding: var(--padding_btn);
    gap: 5px;
    border: none;
    outline: none;
    border-radius: 5px;
    overflow-y: auto;
}

.contextMenu hr {
    border: none;
    border-top: 1px solid var(--bg3);
    margin: 0 var(--padding_btn);
}

.contextMenu>p {
    padding: var(--padding_btn);
}

.contextMenu .captionText {
    opacity: 0.5;
}