.svgContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.svgContainer div {
    max-width: 380px;
}

.svgContainer svg {
    padding: 10%;
}