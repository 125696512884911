.file .hero {
    margin-bottom: -8px;
}

.card.file.generated img {
    object-fit: contain;
    padding: 15%;
}

.card.file.generated:nth-child(2) img,
.card.file.generated:nth-child(5) img {
    background-color: #000;
}

.file.selected {
    outline: 2px solid var(--text_color);
}

.filePopup {
    display: grid;
    gap: var(--gap);
    grid-template-columns: 1fr 300px;
}

.filePopup .filePopup_hero {
    aspect-ratio: 1;
    width: 500px;
    max-width: 100%;
    background-color: var(--bg1);
}

.filePopup .filePopup_hero img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.filePopup .filePopup_content {
    display: flex;
    flex-direction: column;
    gap: var(--gap_min);
}

@media screen and (max-width: 768px) {
    .filePopup {
        grid-template-columns: 1fr;
    }

    .filePopup .filePopup_hero {
        width: 100%;
        background-color: var(--bg1);
    }
}