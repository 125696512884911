.settingsPage>.pageBody {
    display: grid;
    grid-template-columns: 180px 1fr;
}

.settingsPage .settingsMenu {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.settingsMenu hr {
    margin: 0 var(--padding_btn);
}

.userSettingsTitle {
    margin: 0 var(--padding_btn);
    padding-top: var(--gap_min);
    border-bottom: solid 1px var(--bg2);
    width: calc(100% - 2 * var(--padding_btn));
}

@media (max-width: 800px) {
    .settingsPage>.pageBody {
        grid-template-columns: 1fr;
    }

    .settingsPage .settingsMenu {
        flex-direction: row;
        flex-wrap: wrap;
    }
}